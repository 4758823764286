import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/cooking/Hero";
import Main from "../../../sections/cooking/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemo";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from '../../../assets/image/metaimg/cooking.jpg';

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Make Cooking a breeze with Interactive Cookalong videos"
          description="Enable cooking videos to have methods, utensils and products right at their fingertips"
          image={metaImg}
        />     
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.1.1.0.css?v=cooking'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.1.1.0.js?v=cooking'}></script>
        </Helmet>                     
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional">Instructional</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional/cooking">Cooking</Link>
              </li>                            
              </ol>
           </nav>
        </div>             
        <Hero />
        <WaveReverse pos="top" color="#0c426d" background="#fff" /> 
        <VideoDemo className="bg-digma-dark" name="Cook-a-long With Myles from Mindful Chef" projectId="b9b9988d-3548-4c1f-b1dd-592ade91602f" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
